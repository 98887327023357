import { Layout, BackGroundLayout } from './styles';
import MSLoginButton from '@/components/NewLogin/MSLoginButton';

const NewLogin = () => {
  return (
    <Layout>
      <BackGroundLayout>
        Fashion
        <br />& Forward
      </BackGroundLayout>
      <MSLoginButton />
    </Layout>
  );
};

export default NewLogin;
