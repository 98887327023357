import { disableMessage } from '@/utils/firebase/initialize';
import { useMediaQuery } from 'usehooks-ts';
import { minBreakpoints } from '@/utils/mediaQuery';
import { useEffect } from 'react';
import { rnPermissionDeny } from '@/utils/function';
import NewLogin from '@/components/NewLogin';

const Page = () => {
  const isDeskTop = useMediaQuery(`${minBreakpoints.md}`);
  isDeskTop && disableMessage();

  useEffect(() => {
    //RN에서는 이 화면이 오면 세션만료시킨다.
    rnPermissionDeny(4);
  }, []);

  return <NewLogin />;
};

export default Page;
