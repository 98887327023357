import React from 'react';
import { useAzureLoginUrl } from '@/hooks/apiHooks';
import Link from 'next/link';
import { LinkContent, WelComeContent } from '@/components/NewLogin/styles';
import { Skeleton } from '@mui/material';
import { Property } from 'csstype';
import FlexDirection = Property.FlexDirection;

type P = {
  title?: string;
  hasMsImage?: boolean;
  direction?: FlexDirection;
};
const MSLoginButton = ({ title = 'Microsoft로 로그인' }: P) => {
  const { data } = useAzureLoginUrl();

  return data ? (
    <Link href={data.msAzureLoginUrl}>
      <WelComeContent>
        반갑습니다.
        <br />
        F&F 포탈에 오신걸 환영합니다.
      </WelComeContent>
      <LinkContent>MS로 로그인</LinkContent>
    </Link>
  ) : (
    <Skeleton style={{ width: '15vw', height: '10vh' }} />
  );
};

export default MSLoginButton;
