import styled from '@emotion/styled';
import { maxBreakpoints, minBreakpoints } from '@/utils/mediaQuery';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--primary-color);
  font-family: var(--font-Pretendard);
`;
const LinkContent = styled.div`
  margin-top: 50px;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: white;
  width: 410px;
  font-size: 16px;
  font-weight: 400;
  @media screen and (${maxBreakpoints.xs}) {
    width: 300px;
  }
`;

const WelComeContent = styled.div`
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
`;

const BackGroundLayout = styled.div`
  position: absolute;
  color: rgba(255, 255, 255, 0.08);
  font-weight: 200;
  cursor: default;
  line-height: 75%;
  white-space: nowrap;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;

  font-size: 156px;
  top: 154px;
  left: 106px;

  @media screen and (${maxBreakpoints.md}) {
    top: 150px;
    left: 25px;
    font-size: 100px;
  }
  @media screen and (${maxBreakpoints.s}) {
    top: 150px;
    left: 25px;
    font-size: 50px;
  }
`;

export { Layout, LinkContent, BackGroundLayout, WelComeContent };
